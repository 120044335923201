import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductMaterialTypes =
  new (class extends ApiBase {
    /**
     * Типы материалов продукта
     *
     * @returns {Promise}
     */
    get() {
      return this._GET(`/courses/product/options/material-types`)
    }
  })()
