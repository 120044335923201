export default () => ({
  options: {
    categoryOptionList: [],
    customerRelationshipTypeList: [],
    gradeOptionList: [],
    kindOptionList: [],
    leaderOptionList: [],
    serviceOptionList: [],
    schoolOptionList: [],
    parameterOptionList: [],
    materialTypeOptionList: [],
    ctpList: [],
    onlineLessonList: [],
    agreementOptionList: [],
    statuses: [],
    promocodeList: [],
  },

  visibility: {
    schoolsOptions: [],
  },

  offers: {
    parametersSelected: [],
  },

  product: {
    agreements: [],
    author: '',
    customerRelationshipType: null,
    description: '',
    documents: [],
    documentTemplates: [],
    extraCategories: [],
    holding: {
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      location: '',
      displayOnlyMonth: false,
    },
    id: null,
    images: [],
    isActive: false,
    isDocumentsUploadRequired: false,
    isExtracurricular: false,
    leaders: [],
    limitByVacantSeats: false,
    mainCategory: null,
    materials: [],
    name: '',
    offers: [],
    program: [],
    recommendedGrades: [],
    requiredDocumentsList: null,
    seats: null,
    vacantSeats: null,
    service: null,
    tags: [],
    template: null,
    type: null,
    visibility: {
      isPublic: false,
      schools: [],
    },
    users: [],
  },

  // Вкладка "Сертификат"

  certificateUserList: [],
})
