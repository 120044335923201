var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VirtualStyle", [
    _vm._v(
      "\n  " +
        _vm._s(_vm.colorsDark) +
        "\n\n  " +
        _vm._s(_vm.colorsLight) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }