import { ApiBanner } from 'ApiRest/Api/Banners/Banner'
import { ApiBannerPositionOptions } from 'ApiRest/Api/Banners/BannerPositionOptions'
import { ApiBannerStatusOptions } from 'ApiRest/Api/Banners/BannerStatusOptions'

export default {
  async fetchBanner({ commit, state }) {
    const { data } = await ApiBanner.get(state.bannerId)

    commit('setTheBanner', data)
  },

  async createBanner({ commit, state }) {
    const { data } = await ApiBanner.post(state.banner)

    commit('setTheBanner', data)
    commit('setBannerId', data.id)
  },

  async updateBanner({ state, commit }) {
    const { data } = await ApiBanner.patch(state.banner.id, state.banner)

    commit('setTheBanner', data)
  },

  async fetchPositions({ commit }) {
    const { data } = await ApiBannerPositionOptions.get()

    commit('setOptions', {
      path: 'positions',
      value: data,
    })
  },

  async fetchStatuses({ commit }) {
    const { data } = await ApiBannerStatusOptions.get()

    commit('setOptions', {
      path: 'statuses',
      value: data,
    })
  },

  fetchOptions({ dispatch }) {
    return Promise.all([dispatch('fetchPositions'), dispatch('fetchStatuses')])
  },
}
