import Vue from 'vue'

export default {
  resetOptions(state) {
    state.options = {
      schools: [],
      grades: [],
      streams: [],
      educationTypes: [],
      students: [],
    }
  },

  resetStudentAssignmentData(state) {
    state.studentAssignmentData = {
      school: null,
      grade: null,
      stream: null,
      educationType: null,
      students: [],
    }
  },

  resetStreamAssignmentData(state) {
    state.streamAssignmentData = {
      school: null,
      grade: null,
      stream: null,
      educationType: null,
      students: [],
    }
  },

  setOption(state, { key, value }) {
    Vue.set(state.options, key, value)
  },

  setStudentAssignmentDataValue(state, { key, value }) {
    Vue.set(state.studentAssignmentData, key, value)
  },

  setStreamAssignmentDataValue(state, { key, value }) {
    Vue.set(state.streamAssignmentData, key, value)
  },
}
