import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnosticsAgreement = new (class extends ApiBase {
  /**
   * Получения доп.соглашения для школы
   *
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/school/${schoolId}/template/diagnostics/agreement`

    return this._GET(url)
  }

  /**
   * Загрузка шаблона доп.соглашения
   *
   * @param {number} schoolId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, data) {
    const url = `/school/${schoolId}/template/diagnostics/agreement/upload`

    return this._POST(url, data)
  }
})()
