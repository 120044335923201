import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOnlineLessonCountByRepeatBeginEndDate =
  new (class extends ApiBase {
    /**
     * Получение инфо по количеству уроков
     *
     * @param {Object} params
     * @param {number} params.onlineLessonId
     * @param {string|null} params.newBeginDate
     * @param {string|null} params.newEndDate
     * @param {number} params.repeatTypeId
     * @returns {Promise<{data: {count: number}}>}
     */
    get({
      onlineLessonId,
      newBeginDate = null,
      newEndDate = null,
      repeatTypeId,
    }) {
      const parameters = {
        newBeginDate,
        newEndDate,
        repeatTypeId,
      }

      return this._GET(
        `/online-lessons/count/${onlineLessonId}?${UrlHelper.param(
          parameters,
        )}`,
      )
    }
  })()
