import ApiBase from 'ApiRest/ApiBase'

export const ApiOrderClientDocument = new (class extends ApiBase {
  /**
   * Удаление документа клиента
   *
   * @param {Object} params
   * @param {number} params.orderId
   * @param {number} params.fileId
   * @returns {Promise}
   */
  delete({ orderId, fileId }) {
    return this._DELETE(`/order/${orderId}/client-document/${fileId}`)
  }
})()
