import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnosticsContract = new (class extends ApiBase {
  /**
   * Получения договора для школы
   *
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/school/${schoolId}/template/diagnostics/contract`

    return this._GET(url)
  }

  /**
   * Загрузка шаблона договора
   *
   * @param {number} schoolId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, data) {
    const url = `/school/${schoolId}/template/diagnostics/contract/upload`

    return this._POST(url, data)
  }
})()
