import ApiBase from 'ApiRest/ApiBase'

export const ApiProductPromocodeGroup = new (class extends ApiBase {
  /**
   * @param {number} promocodeGroupId
   * @returns {Promise}
   */
  get(promocodeGroupId) {
    return this._GET(`/product/promocode-group/${promocodeGroupId}`)
  }

  /**
   * @param {number} promocodeGroupId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(promocodeGroupId, data) {
    return this._PATCH(`/product/promocode-group/${promocodeGroupId}`, data)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/product/promocode-group`, data)
  }

  /**
   * @param {number} promocodeGroupId
   * @returns {Promise}
   */
  delete(promocodeGroupId) {
    return this._DELETE(`/product/promocode-group/${promocodeGroupId}`)
  }
})()
