import banner from './banner/store'
import categories from './categories/store'
import order from './order/store'
import orders from './orders/store'
import parameter from './parameter/store'
import parameters from './parameters/store'
import product from './product/store'
import productList from './productList/store'
import request from './request/store'
import requests from './requests/store'

export default {
  namespaced: true,

  modules: {
    banner,
    categories,
    order,
    orders,
    parameter,
    parameters,
    product,
    productList,
    request,
    requests,
  },
}
