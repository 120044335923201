import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductCustomerRelationshipTypes =
  new (class extends ApiBase {
    /**
     * Список типов заключения отношений с клиентом
     *
     * @returns {Promise}
     */
    get() {
      return this._GET(`/courses/product/options/customer-relationship-types`)
    }
  })()
