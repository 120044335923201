import ApiBase from 'ApiRest/ApiBase'

export const ApiOrderStatusOptions = new (class extends ApiBase {
  /**
   * Список статусов заказа
   *
   * @returns {Promise}
   */
  get() {
    return this._GET(`/order/option/statuses`)
  }
})()
