import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} applicationId
   * @param {Object} data
   * @returns {string}
   */
  post(applicationId, data) {
    return this._POST(
      `/diagnostics/applications/${applicationId}/testing-screen/create`,
      data,
    )
  }
})()
