import ApiBase from 'ApiRest/ApiBase'

export const ApiProductPromocodeGroupList = new (class extends ApiBase {
  /**
   * Список групп промокодов
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/product/promocode-groups?${this.getUrlParams(parameters)}`,
    )
  }
})()
