<template>
  <UiKitSelect
    :clearable="allowEmpty"
    :close-on-select="closeOnSelect"
    :disabled="disabled"
    :filterable="internalSearch"
    :get-option-key="getOptionKey"
    :get-option-label="getOptionLabel"
    :input-id="inputId"
    :label="name"
    :loading="loading"
    :max-height="maxHeightInPx"
    :multiple="multiple"
    :no-drop="isNoDrop"
    :no-options-txt="noOptions"
    :options="optionsFiltered"
    :placeholder="placeholder"
    :searchable="searchable"
    :selectable="selectable"
    :taggable="taggable"
    :value="value"
    @close="onClose"
    @input="onInput"
    @search="onSearch"
    @option:deselected="onOptionDeselect"
    @option:selected="onOptionSelect"
    @option:created="onOptionCreate"
  />
</template>

<script>
import UiKitSelect from '@admin/ui/admin/UiKitSelect.vue'
import { isArray, uniqueId } from 'lodash'

export default {
  name: 'MultiselectRu',

  components: {
    UiKitSelect,
  },

  props: {
    /**
     * Removed vue-multiselect props:
     * noResult - slot - Shows when no elements match the search query. Defaults to string: No elements found. Consider changing the search query.
     * tagPlaceholder - 'Press enter to create a tag' - String to show when highlighting a potential tag
     * showNoResults - true - Show the noResult slot if no results are found.
     */

    /**
     * vue-multiselect:
     * label - undefined - Label from option Object, that will be visible in the dropdown.
     *
     * vue-select:
     * label - 'label' - Tells vue-select what key to use when generating option labels when each option is an object.
     */
    name: {
      type: String,
      default: 'name',
    },

    /**
     * vue-multiselect:
     * placeholder - 'Select option' - Equivalent to the placeholder attribute on a <select> input.
     *
     * vue-select:
     * placeholder - '' - Equivalent to the placeholder attribute on an <input>.
     */
    placeholder: {
      type: String,
      default() {
        return this.$t('ui.props_default.placeholder')
      },
    },

    /**
     * vue-multiselect:
     * allowEmpty - true - Allows to remove all selected values. Otherwise one must be left selected.
     *
     * vue-select:
     * clearable - true - Can the user clear the selected property?
     */
    allowEmpty: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * trackBy - 'id' - Used to compare objects. Only use if options are objects.
     *
     * vue-select:
     * getOptionKey - Function - Enable/disable creating options from searchInput.
     */
    id: {
      type: String,
      default: 'id',
    },

    /**
     * vue-multiselect:
     * taggable - false - Disable / Enable tagging
     *
     * vue-select:
     * taggable - false - Enable/disable creating options from searchInput.
     */
    taggable: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * noOptions - slot - Shows when no elements in options empty. Defaults to string: List is empty.
     */
    noOptions: {
      type: String,
      default() {
        return this.$t('ui.props_default.list_is_empty')
      },
    },

    /**
     * vue-multiselect:
     * closeOnSelect - true - Enable/disable closing after selecting an option
     *
     * vue-select:
     * closeOnSelect - true - Close a dropdown when an option is chosen. Set to false to keep the dropdown open (useful when combined with multi-select, for example)
     */
    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    /**
     * vue-multiselect:
     * customLabel - Function - Function used to create a custom label
     *
     * vue-select:
     * getOptionLabel - Function - Callback to generate the label text. If {option} is an object, returns option[this.label] by default.
     */
    customLabel: {
      type: Function,
      default(option) {
        if (
          typeof option === 'object' &&
          option !== null &&
          option[this.name] !== undefined
        ) {
          return option[this.name]
        }

        return option
      },
    },

    /**
     * vue-multiselect:
     * disabled - false - Enable/disable the multiselect.
     *
     * vue-select:
     * disabled - false - Disable the entire component.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * hideSelected - false - Hide already selected options
     */
    hideSelected: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * options - undefined - Array of available options: Objects, Strings or Integers. If array of objects, visible label will default to option.label.
     *
     * vue-select:
     * options - [] - An array of strings or objects to be used as dropdown choices. If you are using an array of objects, vue-select will look for a label key (ex. [{label: 'Canada', value: 'CA'}]). A custom label key can be set with the label prop.
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * vue-multiselect:
     * value - undefined - Presets the selected options.
     *
     * vue-select:
     * value - null - Contains the currently selected value. Very similar to a value attribute on an <input>. You can listen for changes using the 'input' event.
     */
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },

    /**
     * vue-multiselect:
     * multiple - false - Equivalent to the multiple attribute on a <select> input.
     *
     * vue-select:
     * multiple - false - Equivalent to the multiple attribute on a <select> input.
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * internalSearch - true - Decide whether to filter the results internally based on search query. Useful for async filtering, where we search through more complex data.
     *
     * vue-select:
     * filterable - true - When true, existing options will be filtered by the search text. Should not be used in conjunction with taggable.
     */
    internalSearch: {
      type: Boolean,
      default: true,
    },

    /**
     * vue-multiselect:
     * loading - false - Show/hide the loading spinner.
     *
     * vue-select:
     * loading - false - Show spinner if the component is in a loading state.
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * vue-multiselect:
     * maxHeight - 300 - Sets max-height style value of the dropdown
     *
     * vue-select:
     * maxHeight - '400px' - Sets the max-height property on the dropdown list. This prop was removed in v3.0. You can use the $vs-dropdown-max-height SCSS variable to adjust this setting in v3.x.
     */
    maxHeight: {
      type: Number,
      default: 300,
    },

    /**
     * vue-multiselect:
     * searchable - true - Add / removes search input.
     *
     * vue-select:
     * searchable - true - Enable/disable filtering the options.
     */
    searchable: {
      type: Boolean,
      default: true,
    },

    /**
     * vue-multiselect:
     * showLabels - true - Decide whether to show labels on highlighted options
     */
    showLabels: {
      type: Boolean,
      default: true,
    },

    /**
     * vue-select:
     * The selectable prop determines if an option is selectable or not. If selectable returns false for a given option, it will be displayed with a vs__dropdown-option--disabled class. The option will be disabled and unable to be selected.
     */
    selectable: {
      type: Function,
      default(option) {
        return option.$isDisabled !== true
      },
    },
  },

  computed: {
    /**
     * @returns {(string|number|null)[]}
     */
    valueKeys() {
      if (!this.multiple) {
        return [this.getOptionKey(this.value)]
      }

      return this.value.map((value) => this.getOptionKey(value))
    },

    /**
     * @returns {(string|number|null)[]}
     */
    valueLabels() {
      return this.value.map((value) => this.getOptionLabel(value))
    },

    /**
     * @returns {boolean}
     */
    shouldHideSelectedOptions() {
      return this.hideSelected && this.value !== null
    },

    /**
     * @returns {Object[]}
     */
    optionsHiddenSelected() {
      return this.options.filter(
        (option) => !this.valueKeys.includes(this.getOptionKey(option)),
      )
    },

    /**
     * @returns {(Object|string|number)[]}
     */
    optionsFiltered() {
      if (this.shouldHideSelectedOptions) {
        return this.optionsHiddenSelected
      }

      return this.options
    },

    /**
     * @returns {string}
     */
    maxHeightInPx() {
      return `${this.maxHeight}px`
    },

    /**
     * @returns {string}
     */
    inputId() {
      return `select-${uniqueId()}`
    },

    /**
     * @returns {boolean}
     */
    isNoDrop() {
      return this.taggable
    },
  },

  methods: {
    /**
     * @param {Object|string|number} option
     * @returns {string|number|null}
     */
    getOptionKey(option) {
      if (typeof option === 'object' && option[this.id] !== undefined) {
        return option[this.id]
      }

      try {
        return JSON.stringify(option)
      } catch (e) {
        return null
      }
    },

    /**
     * @param {Object|string|number} option
     * @returns {string|number|null}
     */
    getOptionLabel(option) {
      if (!this.showLabels) {
        return this.getOptionKey(option)
      }

      return this.customLabel(option)
    },

    onClose() {
      this.$emit('close', this.value, this.inputId)
    },

    /**
     * @param {Object|string|number} option
     */
    onInput(option) {
      this.$emit('input', option, this.inputId)
    },

    /**
     * @param {Object|string|number} option
     */
    onOptionSelect(option) {
      let value

      // TODO: remove when fixed
      // @option:selected return whole multiple selection and not the single involved option
      // https://github.com/sagalbot/vue-select/issues/1624
      if (isArray(option)) {
        value = option.at(this.taggable && option.length > 1 ? -2 : -1)
      } else {
        value = option
      }

      this.$emit('select', value, this.inputId)
    },

    /**
     * @param {Object|string|number} option
     */
    onOptionDeselect(option) {
      this.$emit('remove', option, this.inputId)
    },

    /**
     * @param {Object|string|number} option
     */
    onOptionCreate(option) {
      const label = this.getOptionLabel(option)

      if (!this.valueLabels.includes(label)) {
        this.$emit('tag', this.getOptionLabel(option), this.inputId)
      }
    },

    /**
     * @param {string} searchText
     */
    onSearch(searchText) {
      this.$emit('search-change', searchText, this.inputId)
    },
  },
}
</script>
