import { offerTempIdPrefix } from '@admin/const/productManagement/products/offerTempIdPrefix'
import { RESET_STATE } from 'Helpers/ResetState'
import { set, uniqueId } from 'lodash'
import Vue from 'vue'

import createState from './state'

const createVisibilitySchool = (school) => ({
  school,
  allEducationTypes: false,
  educationTypes: [],
  allGrades: false,
  grades: [],
  allStreams: false,
  streams: [],
})

const createVisibilitySchoolOptions = () => ({
  educationTypes: [],
  grades: [],
  streams: [],
})

const createParameter = (parameter) => ({
  id: parameter.id,
  name: parameter.name,
  option: null,
})

const createOffer = (id) => ({
  id,
  inArchive: false,
  parameters: [],
  prices: {
    basePrice: null,
    specials: [],
  },
})

const getOfferIndex = (state, offerId) =>
  state.product.offers.findIndex((offer) => offer.id === offerId)

const changeOfferArchiveStatus = ({ state, offerId, isInArchive }) => {
  const index = getOfferIndex(state, offerId)

  state.product.offers[index].inArchive = isInArchive

  state.product.offers[index].prices.specials.forEach((special) => {
    special.inArchive = isInArchive
  })
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProduct(state, payload) {
    state.product = payload
  },

  setOptionsList(state, { path, value }) {
    set(state.options, path, value)
  },

  setProductValue(state, { path, value }) {
    set(state.product, path, value)
  },

  // TODO: Унифицировать
  setValue(state, { path, value }) {
    set(state, path, value)
  },

  resetOptions(state, optionList) {
    optionList.forEach((option) => {
      set(state, option, [])
    })
  },

  removeSchool(state, index) {
    state.product.visibility.schools.splice(index, 1)
  },

  // Вкладка "Основные данные — Блок Программы"

  addProgram(state, payload) {
    state.product.program.push(payload)
  },

  removeProgram(state, index) {
    state.product.program.splice(index, 1)
  },

  updateProgram(state, { index, value }) {
    Vue.set(state.product.program, index, value)
  },

  addLeader(state, payload) {
    state.product.leaders.push(payload)
  },

  removeLeader(state, index) {
    state.product.leaders.splice(index, 1)
  },

  updateLeader(state, { index, value }) {
    Vue.set(state.product.leaders, index, value)
  },

  // Вкладка "Видимость"

  setVisibilitySchoolsOptionsList(state, { index, option, value }) {
    if (state.visibility.schoolsOptions[index]) {
      state.visibility.schoolsOptions[index][option] = value
    } else {
      Vue.set(state.visibility.schoolsOptions, index, {})
      state.visibility.schoolsOptions[index][option] = value
    }
  },

  addVisibilitySchoolsOptions(state) {
    state.visibility.schoolsOptions.push(createVisibilitySchoolOptions())
  },

  addVisibilitySchool(state, school) {
    state.product.visibility.schools.push(createVisibilitySchool(school))
  },

  setOptionVisibility(state, { index, option, value }) {
    state.product.visibility.schools[index][option] = value
  },

  // Вкладка "Стоимость"

  addParameter(state, parameter) {
    state.product.offers.forEach((offer) => {
      offer.parameters.push(createParameter(parameter))
    })
  },

  removeParameter(state, index) {
    state.product.offers.forEach((offer) => {
      offer.parameters.splice(index, 1)
    })
  },

  setParametersSelectedInitialValue(state) {
    const { offers } = state.product

    if (offers.length > 0) {
      const parameterIds = offers[0].parameters.map((param) => param.id)

      state.offers.parametersSelected =
        state.options.parameterOptionList.filter((param) =>
          parameterIds.includes(param.id),
        )
    }
  },

  setParametersSelectedValue(state, payload) {
    state.offers.parametersSelected = payload
  },

  /**
   * @param {Object|undefined} state
   * @param {Object} payload
   * @param {boolean} payload.inArchive
   * @param {number} payload.offerIndex
   * @param {number} payload.paramIndex
   * @param {Object} payload.value
   */
  updateParameterOption(state, { inArchive, offerIndex, paramIndex, value }) {
    state.product.offers.filter((offer) => offer.inArchive === inArchive)[
      offerIndex
    ].parameters[paramIndex].option = value
  },

  /**
   * @param {Object|undefined} state
   * @param {Object} payload
   * @param {boolean} payload.inArchive
   * @param {number} payload.offerIndex
   * @param {number} payload.value
   */
  updateBasePrice(state, { inArchive, offerIndex, value }) {
    state.product.offers.filter((offer) => offer.inArchive === inArchive)[
      offerIndex
    ].prices.basePrice = value
  },

  addOffer(state) {
    const { offers } = state.product

    const offer = createOffer(uniqueId(offerTempIdPrefix))

    if (offers.length > 0) {
      state.product.offers[0].parameters.forEach((parameter) => {
        offer.parameters.push(createParameter(parameter))
      })
    }

    state.product.offers.push(offer)
  },

  archiveOffer(state, offerId) {
    changeOfferArchiveStatus({
      state,
      offerId,
      isInArchive: true,
    })
  },

  recoverOffer(state, offerId) {
    changeOfferArchiveStatus({
      state,
      offerId,
      isInArchive: false,
    })
  },

  removeOffer(state, offerId) {
    state.product.offers.splice(getOfferIndex(state, offerId), 1)
  },

  cleanupOffers(state) {
    state.product.offers.forEach((offer, index) => {
      if (String(offer.id).startsWith(offerTempIdPrefix)) {
        state.product.offers[index].id = null
      }
    })
  },

  /**
   * @param {Object|undefined} state
   * @param {Object} payload
   * @param {boolean} payload.inArchive
   * @param {number} payload.offerIndex
   * @param {Array} payload.value
   */
  setSpecialOffers(state, { inArchive, offerIndex, value }) {
    state.product.offers.filter((offer) => offer.inArchive === inArchive)[
      offerIndex
    ].prices.specials = value
  },

  // Вкладка "Материалы"

  addMaterial(state, material) {
    state.product.materials.push(material)
  },

  removeMaterial(state, index) {
    state.product.materials.splice(index, 1)
  },

  setMaterial(state, { index, material }) {
    state.product.materials[index] = material
  },

  // Вкладка "Сертификат"

  setCertificateUserList(state, payload) {
    state.certificateUserList = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @throws {Error}
   */
  setCertificateUser(state, payload) {
    const index = state.certificateUserList.findIndex(
      ({ key }) => key === payload.key,
    )

    if (index === -1) {
      throw new Error(
        `Can not find user with ID ${payload.user.id} in certificateUserList`,
      )
    }

    state.certificateUserList[index] = payload
  },

  // Вкладка "Документы"

  addAgreement(state, payload) {
    state.product.agreements.push(payload)
  },

  removeAgreement(state, index) {
    state.product.agreements.splice(index, 1)
  },

  updateAgreement(state, { index, value }) {
    Vue.set(state.product.agreements, index, value)
  },
}
