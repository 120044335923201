import ApiBase from 'ApiRest/ApiBase'

export const ApiBannerPositionOptions = new (class extends ApiBase {
  /**
   * Список статусов баннера
   *
   * @returns {Promise}
   */
  get() {
    return this._GET(`/options/banner-positions`)
  }
})()
