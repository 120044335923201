import ApiBase from 'ApiRest/ApiBase'

export const ApiIncomeDiagnosticsExtramuralAgreement =
  new (class extends ApiBase {
    /**
     * Файл доп.соглашения для заочной диагностики
     *
     * @param {number} applicationId
     * @returns {Promise}
     */
    get(applicationId) {
      const url = `/diagnostics/applications/${applicationId}/extramural-agreement`
      const config = {
        responseType: 'blob',
      }

      return this._GET(url, config)
    }
  })()
