import { ApiCtpIsGroupLinked } from 'ApiRest/Api/Ctp/IsGroupLinked'
import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import ApiOption from 'ApiRest/Api/Option'
import { ApiProductCertificateIssue } from 'ApiRest/Api/Products/Certificate/issue'
import { ApiProductCertificateMassIssue } from 'ApiRest/Api/Products/Certificate/Massissue'
import { ApiProductCertificateRevoke } from 'ApiRest/Api/Products/Certificate/Revoke'
import { ApiProductCertificateUserList } from 'ApiRest/Api/Products/Certificate/UserList'
import { ApiProductManagementParametersList } from 'ApiRest/Api/Products/Parameters/ParametersList'
import { ApiProductManagement } from 'ApiRest/Api/Products/Product'
import { ApiProductManagementProductAgreements } from 'ApiRest/Api/Products/ProductAgreements'
import { ApiProductManagementProductCategories } from 'ApiRest/Api/Products/ProductCategories'
import { ApiProductCustomerRelationshipTypes } from 'ApiRest/Api/Products/ProductCustomerRelationshipTypes'
import { ApiProductManagementProductLeaders } from 'ApiRest/Api/Products/ProductLeaders'
import { ApiProductManagementProductMaterialTypes } from 'ApiRest/Api/Products/ProductMaterialTypes'
import { ApiProductManagementProducPromocodeTypes } from 'ApiRest/Api/Products/ProductPromocodeTypes'
import { ApiProductManagementProductTypes } from 'ApiRest/Api/Products/ProductTypes'

const commonParameters = {
  expand:
    'images,type,mainCategory,extraCategories,holding,tags,service,documents,materials,template,leaders,visibility,offers,users,agreements,documentTemplates,certificateTextOverlaySettings,certificateTemplate',
}

export default {
  async fetchGradeOptions({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptionsList', {
      path: 'gradeOptionList',
      value: data,
    })
  },

  async fetchInvoiceServiceOptions({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setOptionsList', {
      path: 'serviceOptionList',
      value: data,
    })
  },

  async fetchSchoolOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schoolOptionList',
      value: data,
    })
  },

  async fetchTypeOptions({ commit }) {
    const { data } = await ApiProductManagementProductTypes.get()

    commit('setOptionsList', {
      path: 'kindOptionList',
      value: data,
    })
  },

  async fetchMaterialTypeOptions({ commit }) {
    const { data } = await ApiProductManagementProductMaterialTypes.get()

    commit('setOptionsList', {
      path: 'materialTypeOptionList',
      value: data,
    })
  },

  async fetchCategoryOptions({ commit }) {
    const { data } = await ApiProductManagementProductCategories.get()

    commit('setOptionsList', {
      path: 'categoryOptionList',
      value: data,
    })
  },

  async fetchLeaderOptions({ commit }) {
    const { data } = await ApiProductManagementProductLeaders.get()

    commit('setOptionsList', {
      path: 'leaderOptionList',
      value: data,
    })
  },

  async fetchParameterOptions({ commit }) {
    const { data } = await ApiProductManagementParametersList.get()

    commit('setOptionsList', {
      path: 'parameterOptionList',
      value: data,
    })
  },

  async fetchCtpOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getCtpsLite(params)

    commit('setOptionsList', {
      path: 'ctpList',
      value: data,
    })
  },

  async fetchOnlineLessonOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getOnlineLessonsLite(params)

    commit('setOptionsList', {
      path: 'onlineLessonList',
      value: data,
    })
  },

  async fetchCustomerRelationshipTypeOptions({ commit }) {
    const { data } = await ApiProductCustomerRelationshipTypes.get()

    commit('setOptionsList', {
      path: 'customerRelationshipTypeList',
      value: data,
    })
  },

  async fetchAgreementOptions({ commit }) {
    const { data } = await ApiProductManagementProductAgreements.get()

    commit('setOptionsList', {
      path: 'agreementOptionList',
      value: data,
    })
  },

  async fetchStatusOptions({ commit }) {
    const { data } = await ApiOption.getProductStatuses()

    commit('setOptionsList', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchPromocodeOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiProductManagementProducPromocodeTypes.get(params)

    commit('setOptionsList', {
      path: 'promocodeList',
      value: data,
    })
  },

  async fetchProductOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchCategoryOptions'),
      dispatch('fetchGradeOptions'),
      dispatch('fetchInvoiceServiceOptions'),
      dispatch('fetchLeaderOptions'),
      dispatch('fetchParameterOptions'),
      dispatch('fetchSchoolOptions'),
      dispatch('fetchTypeOptions'),
      dispatch('fetchMaterialTypeOptions'),
      dispatch('fetchCustomerRelationshipTypeOptions'),
      dispatch('fetchAgreementOptions'),
      dispatch('fetchStatusOptions'),
    ])
  },

  async createProduct({ commit, state }) {
    commit('cleanupOffers')

    const { data } = await ApiProductManagement.post(
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchProduct({ commit }, productId) {
    const { data } = await ApiProductManagement.get(productId, commonParameters)

    commit('setProduct', data)
  },

  async updateProduct({ commit, state }, productId) {
    commit('cleanupOffers')

    const { data } = await ApiProductManagement.put(
      productId,
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchEducationTypeVisibilitySchoolOptions(
    { commit },
    { index, params },
  ) {
    const { data } = await ApiOption.getEducationTypes(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'educationTypes',
      value: data,
    })
  },

  async fetchGradeVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getGrades(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'grades',
      value: data,
    })
  },

  async fetchStreamVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getStreamsLite(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'streams',
      value: data,
    })
  },

  async fetchVisibilitySchoolOptions({ dispatch }, { index, params }) {
    const payload = {
      index,
      params,
    }

    await Promise.all([
      dispatch('fetchEducationTypeVisibilitySchoolOptions', payload),
      dispatch('fetchGradeVisibilitySchoolOptions', payload),
      dispatch('fetchStreamVisibilitySchoolOptions', payload),
    ])
  },

  fetchSpecialOfferSchoolOptions() {
    return ApiOption.getSchools()
  },

  fetchSpecialOfferGradeOptions(context, params) {
    return ApiOption.getGrades(params)
  },

  fetchSpecialOfferEducationTypeOptions(context, params) {
    return ApiOption.getEducationTypes(params)
  },

  async fetchCertificateUserList({ commit }, productId) {
    const { data } = await ApiProductCertificateUserList.get({
      productId,
    })

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async issueCertificate({ commit }, payload) {
    const { data } = await ApiProductCertificateIssue.put(payload)

    commit('setCertificateUser', data)

    return data
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Array>}
   */
  async massIssueCertificate({ commit }, payload) {
    const { data } = await ApiProductCertificateMassIssue.put(payload)

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async revokeCertificate({ commit }, payload) {
    const { data } = await ApiProductCertificateRevoke.delete(payload)

    commit('setCertificateUser', data)

    return data
  },

  canAddCtp(context, ctpId) {
    return ApiCtpIsGroupLinked.get(ctpId)
  },

  async fetchGroupOnlineLessons(context, lessonId) {
    const { data } = await ApiOnlineLessonOption.getGroupOnlineLessons(lessonId)

    return data
  },
}
