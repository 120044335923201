import { ApiOnlineLessonRecordings } from 'ApiRest/Api/OnlineLesson/Recordings'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} lessonId
   * @returns {Promise<void>}
   */
  async fetchRecordings({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonRecordings.get(lessonId)

    commit('setRecordings', data.videos)
  },

  /**
   *
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} parameters
   * @param {number} parameters.lessonId
   * @param {Object} parameters.payload
   * @returns {Promise<void>}
   */
  async updateRecordings({ commit }, { lessonId, payload }) {
    const { data } = await ApiOnlineLessonRecordings.patch(lessonId, payload)

    commit('setRecordings', data.videos)
  },
}
