import ApiBase from 'ApiRest/ApiBase'

export const ApiProductPromocodeGroupProductOptions =
  new (class extends ApiBase {
    /**
     * Список опций продуктов
     *
     * @returns {Promise}
     */
    get() {
      return this._GET(`/product-promocodes/options/products`)
    }
  })()
