export default () => ({
  onlineLessons: [],
  theOnlineLesson: {
    conferenceType: '',
    id: null,
    name: '',
    lessonDate: '',
    timeStart: '',
    timeEnd: '',
    lessonUrl: '',
    lessonUrlHidden: false,
    prereading: '',
    recommendedHomework: '',
    repeatType: null,
    copyTheme: false,
    repeatBeginDate: null,
    repeatEndDate: null,
    additionalInfo: '',
    // TODO: Должно всегда оставаться true, для определения беком, что это онлайн урок витрины.
    //  После рефакторинга бека нужно удалить.
    isShowcase: true,
    subjects: [],
    grades: [],
    managers: [],
    allGrades: false,
    alLSubjects: false,
    allManagers: false,
  },
  isBigBlueButtonLessonDisabled: true,
  isOnlineLessonRunning: false,
  newRepeatBeginEndDateCount: null,
  option: {
    grades: [],
    subjects: [],
    managers: [],
    educationTypes: [],
    onlineLessonRepeatTypes: [],
  },
  analytics: [],
})
