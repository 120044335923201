import ApiApplication from 'ApiRest/Api/IncomeDiagnostics/Application'
import ApiApplications from 'ApiRest/Api/IncomeDiagnostics/Applications'
import { ApiDiagnosticsAvailableStatuses } from 'ApiRest/Api/IncomeDiagnostics/AvailableApplicationStatuses'
import ApiConfiguration from 'ApiRest/Api/IncomeDiagnostics/Configuration'
import ApiDiagnosticsCreateRetake from 'ApiRest/Api/IncomeDiagnostics/CreateRetake'
import { ApiDiagnosticsCtpTestList } from 'ApiRest/Api/IncomeDiagnostics/CtpList'
import { ApiIncomeDiagnosticsExtramuralAgreement } from 'ApiRest/Api/IncomeDiagnostics/ExtramuralAgreement'
import { ApiDiagnosticsExtramural } from 'ApiRest/Api/IncomeDiagnostics/ExtramuralDiagnostics'
import ApiDiagnosticsFailedSubjects from 'ApiRest/Api/IncomeDiagnostics/FailedSubjects'
import { ApiDiagnosticsFullTime } from 'ApiRest/Api/IncomeDiagnostics/FullTimeDiagnostics'
import ApiSettingsSubjects from 'ApiRest/Api/IncomeDiagnostics/SettingsSubjects'
import ApiUploadTestingFile from 'ApiRest/Api/IncomeDiagnostics/UploadTestingFile'
import ApiOption from 'ApiRest/Api/Option'
import ApiSchoolList from 'ApiRest/Api/School/List'
import ApiTestingCheckTestList from 'ApiRest/Api/Test/List'
import ApiUserStudentAccountInactive from 'ApiRest/Api/User/Student/AccountInactive'
import Constants from 'Constants'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchApplications({ commit }, params) {
    const response = await ApiApplications.get(params)

    commit('setApplications', response.data)

    return response
  },

  async fetchApplication({ commit, state }) {
    const params = {
      expand:
        'status,school,grade,client,student,invoice,retakes,passingSubjects,additionalInvoice',
    }

    const { data } = await ApiApplication.get(state.applicationId, params)

    commit('setApplication', data)
  },

  async fetchSchools({ commit }) {
    const options = {
      hasModule: 'income-diagnostics',
    }

    const { data } = await ApiSchoolList.get(options)

    commit('setSchools', data)
  },

  async fetchServices({ commit }, id) {
    const { data } = await ApiOption.getInvoiceServices(id)

    commit('setOptions', {
      key: 'services',
      data,
    })
  },

  async fetchApplicationsStatuses({ commit, state }) {
    const { data } = await ApiDiagnosticsAvailableStatuses.get(
      state.applicationId,
    )

    commit('setApplicationStatuses', data)
  },

  async fetchFailedSubjects({ commit }, applicationid) {
    const { data } = await ApiDiagnosticsFailedSubjects.get(applicationid)

    commit('setOptions', {
      key: 'subjects',
      data,
    })
  },

  updateApplicationStatus({ state }) {
    const payload = {
      status: state.application.status,
    }

    return ApiApplication.patch(state.applicationId, payload)
  },

  async createRetake({ commit }, { applicationId, data }) {
    const { data: payload } = await ApiDiagnosticsCreateRetake.post(
      applicationId,
      data,
    )

    commit('addRetake', payload)
  },

  async fetchConfiguration({ commit }, id) {
    const { data } = await ApiConfiguration.get(id)

    commit('setConfiguration', data)
  },

  async fetchGrades({ commit }, schoolId) {
    const parameters = {
      schoolId,
    }

    const { data } = await ApiOption.getGrades(parameters)

    commit('setOptions', {
      key: 'grades',
      data,
    })
  },

  async fetchSettingsSubjects({ commit }, schoolId) {
    const {
      data: { gradeSubjectSettings },
    } = await ApiSettingsSubjects.get(schoolId)

    commit('setSettingsSubjects', gradeSubjectSettings)
  },

  async fetchManagers({ commit }, schoolId) {
    const { data } = await ApiOption.getCtpCheckers(schoolId)

    commit('setManagers', data)
  },

  async saveGradeSubjectSettings({ state, commit }) {
    const data = {
      gradeSubjectSettings: state.gradeSubjectSettings,
    }

    const {
      data: { gradeSubjectSettings },
    } = await ApiSettingsSubjects.post(state.schoolId, data)

    commit('setSettingsSubjects', gradeSubjectSettings)
  },

  async uploadFilesPassingSubject({ state }, { files, testingId }) {
    const payload = {
      testing: {
        id: testingId,
      },
      files,
    }

    const { data } = await ApiUploadTestingFile.post(
      state.applicationId,
      payload,
    )

    return data
  },

  async fetchExtramuralDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsExtramural.get(
      state.schoolId,
      state.grade.id,
    )

    commit('setSubjectsExtramuralDiagnostics', data)
  },

  async saveExtramuralDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsExtramural.post(
      state.schoolId,
      state.grade.id,
      {
        subjectSettings: state.subjectsExtramuralDiagnostics.subjectSettings,
      },
    )

    commit('setSubjectsExtramuralDiagnostics', data)
  },

  async fetchFullTimeDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsFullTime.get(
      state.schoolId,
      state.grade.id,
    )

    commit('setSubjectsFullTimeDiagnostics', data)
  },

  async fetchTestsList({ commit, state }) {
    const { data } = await ApiTestingCheckTestList.get({
      school: {
        id: state.schoolId,
      },

      type: {
        id: Constants.testingTypes.TEST,
      },
    })

    commit('setTests', data)
  },

  async fetchSubjects({ commit }) {
    const { data } = await ApiOption.getSubjects()

    commit('setSubjects', data)
  },

  async saveFullTimeDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsFullTime.post(
      state.schoolId,
      state.grade.id,
      {
        subjectSettings: state.subjectsFullTimeDiagnostics.subjectSettings,
      },
    )

    commit('setSubjectsFullTimeDiagnostics', data)
  },

  async fetchCheckers({ state }, subjectId) {
    const params = {
      subject: {
        id: subjectId,
      },

      grade: {
        id: state.grade.id,
      },
    }

    const { data } = await ApiOption.getCtpCheckers(state.schoolId, params)

    return data
  },

  async fetchTestsFromCtp({ state }, { subjectId, gradeId }) {
    const { data } = await ApiDiagnosticsCtpTestList.get({
      school: {
        id: state.schoolId,
      },

      grade: {
        id: gradeId,
      },

      subject: {
        id: subjectId,
      },
    })

    return data
  },

  async downloadExtramuralAgreement({ state }) {
    const response = await ApiIncomeDiagnosticsExtramuralAgreement.get(
      state.applicationId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async blockStudent({ commit, state }) {
    const { data } = await ApiUserStudentAccountInactive.put(
      state.application.student.id,
    )

    commit('setStudentAccountStatus', data?.accountStatus)
  },
}
