var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uiKit-contentGroup" },
    [
      _vm.title
        ? _c(
            "h3",
            { staticClass: "title" },
            [
              _vm._v("\n    " + _vm._s(_vm.title) + "\n\n    "),
              _vm.errorsContentGroup
                ? _c("span", { staticClass: "errorsContentGroup" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.errorsContentGroup) + "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("title-after"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }