import ApiBase from 'ApiRest/ApiBase'

export const ApiCtpIsGroupLinked = new (class extends ApiBase {
  /**
   * Проверка привязки КТП к группе
   *
   * @param {number} ctpId
   * @returns {Promise}
   */
  get(ctpId) {
    return this._GET(`/ctp/${ctpId}/is-group-linked`)
  }
})()
