import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Входящая диагностика
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setAbilityByModuleIncomeDiagnostics = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  const moduleIncomeDiagnostics = getActiveModule(
    activeModules,
    'income-diagnostics',
  )

  if (moduleIncomeDiagnostics !== undefined) {
    const routeNames = routes.get('income-diagnostics')

    if (permission === 'income_diagnostics') {
      helpers.allowReadMenuItemForRoutes(routeNames)

      return true
    }

    if (permission === 'income_diagnostics_applications') {
      helpers.allowReadRoutes([
        'admin-income-diagnostics-applications',
        'admin-the-income-diagnostics-application',
      ])

      return true
    }
  }

  return false
}
