import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductCertificateMassIssue =
  new (class extends ApiBase {
    /**
     * Масовая выдача сертификата
     *
     * @param {Object} payload
     * @returns {Promise}
     */
    put(payload) {
      const url = `/courses/product/certificate-issue/mass-issue`

      return this._PUT(url, payload)
    }
  })()
