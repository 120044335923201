import { isNull } from 'lodash'

export default {
  hasProgram(state) {
    return state.product?.program?.length > 0
  },

  schoolsSelected(state) {
    return state.product.visibility.schools.map(({ school }) => school)
  },

  offersInArchive(state) {
    return state.product.offers.filter((offer) => offer.inArchive)
  },

  offersActual(state) {
    return state.product.offers.filter((offer) => !offer.inArchive)
  },

  hasProductId(state) {
    return !isNull(state.product.id)
  },

  hasCustomerRelationshipType(state) {
    return !isNull(state.product.customerRelationshipType)
  },
}
