import ApiBase from 'ApiRest/ApiBase'

export const ApiDiagnosticsAvailableStatuses = new (class extends ApiBase {
  /**
   * @param {number} applicationId
   * @returns {Promise}
   */
  get(applicationId) {
    const url = `/diagnostics/applications/${applicationId}/available-application-statuses`

    return this._GET(url)
  }
})()
