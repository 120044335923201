import ApiBase from 'ApiRest/ApiBase'

export const ApiBannerStatusOptions = new (class extends ApiBase {
  /**
   * Список статусов баннера
   *
   * @returns {Promise}
   */
  get() {
    return this._GET(`/options/banner-statuses`)
  }
})()
