import { student } from '@admin/store/admin/student'

import administratingEducontLog from './administratingEducontLog/store'
import administratingZoom from './administratingZoom/store'
import analytics from './analytics/store'
import analyticsCtp from './analyticsCtp/store'
import analyticsLibrary from './analyticsLibrary/store'
import authAny from './auth/any/store'
import courseManagement from './courseManagement/store'
import credits from './credits/store'
import dashboard from './dashboard/store'
import enrollmentContract from './enrollmentContract/store'
import familyCertification from './familyCertification/store'
import familyCertificationRequest from './familyCertificationRequest/store'
import familyCertificationSchedule from './familyCertificationSchedule/store'
import homework from './homework/store'
import incomeDiagnostics from './incomeDiagnostics/store'
import library from './library/store'
import messages from './messages/store'
import navigation from './navigation/store'
import news from './news/store'
import onlineLesson from './onlineLesson/store'
import onlineLessonRoom from './onlineLessonRoom/store'
import partner from './partner/store'
import paymentServices from './payment/paymentService/store'
import paymentProductPromocode from './payment/productPromocode/store'
import paymentPromocode from './payment/promocode/store'
import price from './price/store'
import productManagement from './productManagement/store'
import requestIncomeDiagnostics from './requestIncomeDiagnostics/store'
import settingsFamilyEducation from './settings/familyEducation/store'
import school from './settings/school/store'
import timetable from './settings/timetable/store'
import settingsWiki from './settings/wiki/store'
import showcaseOnlineLesson from './showcaseOnlineLesson/store'
import studentTransfer from './studentTransfer/store'
import supportFAQ from './support/faq/store'
import supportIdea from './support/idea/store'
import supportQuestion from './support/question/store'
import supportQuestionCounter from './support/questionCounter/store'
import supportQuestionSettings from './support/questionSettings/store'
import theme from './theme/store'
import wiki from './wiki/store'

export default {
  namespaced: true,

  modules: {
    administratingEducontLog,
    administratingZoom,
    analytics,
    analyticsCtp,
    analyticsLibrary,
    authAny,
    credits,
    courseManagement,
    dashboard,
    enrollmentContract,
    familyCertification,
    familyCertificationRequest,
    familyCertificationSchedule,
    homework,
    incomeDiagnostics,
    library,
    messages,
    navigation,
    news,
    onlineLesson,
    onlineLessonRoom,
    partner,
    paymentPromocode,
    paymentProductPromocode,
    paymentServices,
    price,
    productManagement,
    requestIncomeDiagnostics,
    settingsFamilyEducation,
    settingsWiki,
    showcaseOnlineLesson,
    ...student,
    studentTransfer,
    supportFAQ,
    supportIdea,
    supportQuestion,
    supportQuestionCounter,
    supportQuestionSettings,
    school,
    theme,
    timetable,
    wiki,
  },
}
