import { ApiProductManagementAnalyticsList } from 'ApiRest/Api/Products/AnalyticsList'

export default {
  async fetchAnalytics({ commit }, filters = {}) {
    const { data, headers } = await ApiProductManagementAnalyticsList.get(
      filters,
    )

    commit('setFilters', filters)
    commit('setAnalytics', data)
    commit('setPagination', headers)
  },
}
