import { ApiTemplateDiagnosticsAgreement } from 'ApiRest/Api/School/Template/Diagnostics/Agreement'
import { ApiTemplateDiagnosticsApplication } from 'ApiRest/Api/School/Template/Diagnostics/Application'
import { ApiTemplateDiagnosticsContract } from 'ApiRest/Api/School/Template/Diagnostics/Contract'
import { ApiTemplateDiagnostics } from 'ApiRest/Api/School/Template/Diagnostics/Diagnostics'
import { ApiTemplateDiagnosticsSample } from 'ApiRest/Api/School/Template/Diagnostics/Sample'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchTemplateContract({ commit, state }) {
    const { data } = await ApiTemplateDiagnosticsContract.get(state.schoolId)

    commit('setTemplate', {
      key: 'contract',
      payload: data,
    })
  },

  async uploadTemplateContract({ commit, state }, file) {
    const params = {
      documentContentB64: file,
      schoolId: state.schoolId,
    }

    const { data } = await ApiTemplateDiagnosticsContract.post(
      state.schoolId,
      params,
    )

    commit('setTemplate', {
      key: 'contract',
      payload: data,
    })
  },

  async fetchTemplateAgreement({ commit, state }) {
    const { data } = await ApiTemplateDiagnosticsAgreement.get(state.schoolId)

    commit('setTemplate', {
      key: 'agreement',
      payload: data,
    })
  },

  async uploadTemplateAgreement({ commit, state }, file) {
    const params = {
      documentContentB64: file,
      schoolId: state.schoolId,
    }

    const { data } = await ApiTemplateDiagnosticsAgreement.post(
      state.schoolId,
      params,
    )

    commit('setTemplate', {
      key: 'agreement',
      payload: data,
    })
  },

  async fetchTemplateApplication({ commit, state }) {
    const { data } = await ApiTemplateDiagnosticsApplication.get(state.schoolId)

    commit('setTemplate', {
      key: 'application',
      payload: data,
    })
  },

  async uploadTemplateApplication({ commit, state }, file) {
    const params = {
      documentContentB64: file,
      schoolId: state.schoolId,
    }

    const { data } = await ApiTemplateDiagnosticsApplication.post(
      state.schoolId,
      params,
    )

    commit('setTemplate', {
      key: 'application',
      payload: data,
    })
  },

  async downloadEmptyTemplate({ state }, documentId) {
    const response = await ApiTemplateDiagnostics.get(
      state.schoolId,
      documentId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async downloadFilledTemplate({ state }, documentId) {
    const response = await ApiTemplateDiagnosticsSample.get(
      state.schoolId,
      documentId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async removeTemplate({ commit, state }, { key, documentId }) {
    await ApiTemplateDiagnostics.delete(state.schoolId, documentId)

    commit('unsetTemplate', key)
  },
}
