var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "uiKit-navigation" },
            [
              _vm.navigationTitle
                ? _c("div", { staticClass: "uiKit-navigation-title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.navigationTitle))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.localNavigation, function (item, itemIndex) {
                return [
                  item
                    ? _c("UiKitNavigationGroup", {
                        key: itemIndex,
                        attrs: { item: item },
                        on: { toggle: (e) => _vm.processToggle(itemIndex, e) },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c("LoadingElement"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }