export const courseManagementRoutes = [
  {
    path: 'course-management/products',
    name: 'admin-course-management-products',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-products" */ '@admin/pages/admin/courseManagement/product/Products.vue'
      ),
  },
  {
    path: 'course-management/products/:productId(\\d+)',
    name: 'admin-course-management-the-product',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-product" */ '@admin/pages/admin/courseManagement/product/TheProductDetail.vue'
      ),
  },
  {
    path: 'course-management/products/create',
    name: 'admin-course-management-product-create',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-product-create" */ '@admin/pages/admin/courseManagement/product/CreateProduct.vue'
      ),
  },
  {
    path: 'course-management/analytics',
    name: 'admin-course-management-analytics',
    meta: {
      accessControlGroup: 'course-management-analytics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-analytics" */ '@admin/pages/admin/courseManagement/Analytics.vue'
      ),
  },
  {
    path: 'course-management/orders',
    name: 'admin-course-management-orders',
    meta: {
      accessControlGroup: 'course-management-order',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-orders" */
        '@admin/pages/admin/courseManagement/order/Orders.vue'
      ),
  },
  {
    path: 'course-management/order/:orderId(\\d+)',
    name: 'admin-course-management-the-order',
    meta: {
      accessControlGroup: 'course-management-order',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-order" */
        '@admin/pages/admin/courseManagement/order/TheOrder.vue'
      ),
  },
  {
    path: 'course-management/banners',
    name: 'admin-course-management-banners',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-banners" */ '@admin/pages/admin/courseManagement/banner/Banners.vue'
      ),
  },
  {
    path: 'course-management/banner/create',
    name: 'admin-course-management-banner-create',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-banner" */ '@admin/pages/admin/courseManagement/banner/TheBanner.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'course-management/banner/:bannerId(\\d+)',
    name: 'admin-course-management-the-banner',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-banner" */ '@admin/pages/admin/courseManagement/banner/TheBanner.vue'
      ),
  },
  {
    path: 'course-management/categories',
    name: 'admin-course-management-categories',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-categories" */ '@admin/pages/admin/courseManagement/category/Categories.vue'
      ),
  },
  {
    path: 'course-management/categories/create',
    name: 'admin-course-management-category-create',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-category-create" */ '@admin/pages/admin/courseManagement/category/TheCategory.vue'
      ),
  },
  {
    path: 'course-management/categories/:categoryId(\\d+)',
    name: 'admin-course-management-the-category',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-category" */ '@admin/pages/admin/courseManagement/category/TheCategory.vue'
      ),
  },
  {
    path: 'course-management/parameters',
    name: 'admin-course-management-parameters',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-parameters" */ '@admin/pages/admin/courseManagement/parameter/Parameters.vue'
      ),
  },
  {
    path: 'course-management/parameter/create',
    name: 'admin-course-management-the-parameter-create',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-parameter" */ '@admin/pages/admin/courseManagement/parameter/TheParameter.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'course-management/parameter/:parameterId(\\d+)',
    name: 'admin-course-management-the-parameter',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-parameter" */ '@admin/pages/admin/courseManagement/parameter/TheParameter.vue'
      ),
  },
  {
    path: 'course-management/requests',
    name: 'admin-course-management-requests',
    meta: {
      accessControlGroup: 'course-management-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-requests  " */ '@admin/pages/admin/courseManagement/requests/Requests.vue'
      ),
  },
  {
    path: 'course-management/requests/:requestId(\\d+)',
    name: 'admin-course-management-the-request',
    meta: {
      accessControlGroup: 'course-management-request',
    },

    component: () =>
      import(
        /* webpackChunkName: "admin-course-management-the-request" */
        '@admin/pages/admin/courseManagement/requests/TheRequest.vue'
      ),
  },
]
