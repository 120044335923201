import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnosticsSample = new (class extends ApiBase {
  /**
   * Скачивания заполненного шаблона договора/заявления/доп.соглашения
   *
   * @param {number} schoolId
   * @param {number} documentId
   * @returns {Promise}
   */
  get(schoolId, documentId) {
    const url = `/school/${schoolId}/template/diagnostics/${documentId}/sample`
    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
