var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loadOnClick
    ? _c(
        "button",
        {
          staticClass: "btn",
          class: _vm.cssClass,
          attrs: { type: "button", disabled: _vm.isDisabled },
          on: { click: _vm.onClick },
        },
        [
          _vm.isLoading
            ? _c("span", {
                staticClass: "spinner-border spinner-border-sm",
                class: _vm.$style.spinner,
                attrs: { role: "status", "aria-hidden": "true" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { class: _vm.$style.label }, [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
    : _c(
        "button",
        _vm._g(
          {
            staticClass: "btn",
            class: _vm.cssClass,
            attrs: { type: "button", disabled: _vm.isDisabled },
          },
          _vm.$listeners
        ),
        [
          _vm.loading
            ? _c("span", {
                staticClass: "spinner-border spinner-border-sm",
                class: _vm.$style.spinner,
                attrs: { role: "status", "aria-hidden": "true" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { class: _vm.$style.label }, [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }