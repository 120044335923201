<template>
  <div class="student-demo">
    <LoadingElement v-if="isLoading" />

    <template v-else>
      <PageTitle :title="pageTitle" />

      <UiKitContentGroup>
        <div class="row">
          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'lastName')"
              :label="t('last_name.label')"
            >
              <UiKitInput
                v-model="lastName"
                :placeholder="t('last_name.placeholder')"
              />
            </UiKitFormField>
          </div>

          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'firstName')"
              :label="t('first_name.label')"
            >
              <UiKitInput
                v-model="firstName"
                :placeholder="t('first_name.placeholder')"
              />
            </UiKitFormField>
          </div>

          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'middleName')"
              :label="t('middle_name.label')"
            >
              <UiKitInput
                v-model="middleName"
                :placeholder="t('middle_name.placeholder')"
              />
            </UiKitFormField>
          </div>
        </div>

        <div class="row">
          <div v-if="hasAccessPDStudent" class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'password')"
              :label="t('password.label')"
            >
              <UiKitInput
                v-model="password"
                :placeholder="t('password.placeholder')"
              />
            </UiKitFormField>
          </div>

          <div class="col-lg-4 mb-4">
            <UiKitDatePicker
              v-model="demoAccessExpiredAt"
              mode="dateTime"
              :date-label="t('access_until.label')"
            />
          </div>

          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'school')"
              :label="t('school.label')"
            >
              <MultiselectRu
                v-model="detail.school"
                :placeholder="t('school.placeholder')"
                :options="options.schools"
                @input="getGradesAndEducationType"
              />
            </UiKitFormField>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'educationType')"
              :label="t('education_type.label')"
            >
              <MultiselectRu
                v-model="detail.educationType"
                :placeholder="t('education_type.placeholder')"
                :disabled="isEducationTypeSelectDisabled"
                :options="options.educationTypes"
                @input="getGradesAndEducationType"
              />
            </UiKitFormField>
          </div>

          <div class="col-lg-4 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'grade')"
              :label="t('grade.label')"
            >
              <MultiselectRu
                v-model="grade"
                :placeholder="t('grade.placeholder')"
                :disabled="isGradeSelectDisabled"
                :options="options.grades"
              />
            </UiKitFormField>
          </div>
        </div>

        <hr class="contentGroupHr mb-4" />

        <div v-if="hasAccessPDStudent" class="row">
          <div class="col-lg-6 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'email')"
              :label="t('email.label')"
            >
              <UiKitInput
                v-model="email"
                type="email"
                :placeholder="t('email.placeholder')"
              />
            </UiKitFormField>
          </div>

          <div class="col-lg-6 mb-4">
            <UiKitFormField
              :errors="getFieldErrorByPath(errors, 'phone')"
              :label="t('telephone.label')"
            >
              <UiKitInput
                v-model="phone"
                :placeholder="t('telephone.placeholder')"
              />
            </UiKitFormField>
          </div>
        </div>

        <!--
          TODO: Пока не реализовано
          <div class="mt-4">
          <PasswordChange :email="detail.email" />
          </div>
        -->
        <div class="mt-4">
          <UiKitButton
            v-show="canCreateStudentDemo"
            class="btn-success btn-lg"
            :label="t('btn_create')"
            :loading="isLoadingBtns.create"
            :disabled="isLoadingBtns.create"
            @click="create"
          />

          <UiKitButton
            v-show="canUpdateStudentDemo"
            class="btn-success btn-lg"
            :label="t('btn_save')"
            :loading="isLoadingBtns.save"
            :disabled="isLoadingBtns.save"
            @click="save"
          />
        </div>
      </UiKitContentGroup>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-12">
          <h4>{{ parentType }}</h4>

          <table class="table contentGroup">
            <thead>
              <tr>
                <th></th>

                <th>{{ t('filter_grid_id') }}</th>

                <th>
                  {{ t('filter_grid_full_name') }}
                </th>

                <th v-if="hasAccessPDClient">
                  {{ t('filter_grid_telephone') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <template v-if="detail.client">
                <tr>
                  <td class="td-fixed-eye">
                    <router-link
                      target="_blank"
                      rel="noopener noreferrer"
                      class="fas fa-eye detail-link"
                      :to="{
                        name: 'admin-the-client',
                        params: {
                          clientId: detail.client.id,
                        },
                      }"
                    />
                  </td>

                  <td class="td-fixed-id">
                    {{ detail.client.id }}
                  </td>

                  <td>
                    {{ getFullName(detail.client) }}
                  </td>

                  <td v-if="hasAccessPDClient">{{ detail.client.phone }}</td>
                </tr>
              </template>

              <template v-else-if="detail.lead">
                <tr>
                  <td class="td-fixed-eye">
                    <router-link
                      target="_blank"
                      rel="noopener noreferrer"
                      class="fas fa-eye detail-link"
                      :to="{
                        name: 'admin-the-lead',
                        params: {
                          leadId: detail.lead.id,
                        },
                      }"
                    />
                  </td>

                  <td class="td-fixed-id">
                    {{ detail.lead.id }}
                  </td>

                  <td>
                    {{ getFullName(detail.lead) }}
                  </td>

                  <td v-if="hasAccessPDClient">{{ detail.lead.phone }}</td>
                </tr>
              </template>

              <template v-else>
                <tr>
                  <td class="td-fixed-eye"></td>
                  <td class="td-fixed-id"><i class="fa fa-minus"></i></td>
                  <td>{{ t('no_data') }}</td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { userActions } from '@admin/accessControl/userActions'
import LoadingElement from '@admin/ui/admin/LoadingElement.vue'
import MultiselectRu from '@admin/ui/admin/MultiselectRu.vue'
import PageTitle from '@admin/ui/admin/PageTitle.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitContentGroup from '@admin/ui/admin/UiKitContentGroup.vue'
import UiKitDatePicker from '@admin/ui/admin/UiKitDatePicker.vue'
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitInput from '@admin/ui/admin/UiKitInput.vue'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiOptionsDemoEducationTypes from 'Api/rest/Api/Auth/Options/DemoEducationTypes'
import ApiOptionsDemoGrades from 'Api/rest/Api/Auth/Options/DemoGrades'
import ApiOption from 'ApiRest/Api/Option'
import ApiUserStudentDemo from 'ApiRest/Api/User/StudentDemo'
import { NotifyMixin } from 'Helpers/mixins/NotifyMixin'
// import PasswordChange from '@admin/components/admin/student/PasswordChange'

export default {
  name: 'TheStudentDemo',

  components: {
    UiKitDatePicker,
    UiKitFormField,
    UiKitInput,
    MultiselectRu,
    PageTitle,
    UiKitContentGroup,
    UiKitButton,
    // PasswordChange,
    LoadingElement,
  },

  mixins: [NotifyMixin],

  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      detail: {
        firstName: '',
        lastName: '',
        middleName: '',
        password: '',
        email: '',
        phone: '',
        grade: null,
        demoAccessExpiredAt: null,
        client: null,
        lead: null,
        school: null,
        educationType: null,
      },

      isLoadingBtns: {
        save: false,
        create: false,
      },

      options: {
        grades: [],
        schools: [],
        educationTypes: [],
      },
    }
  },

  computed: {
    pageTitle() {
      return `${this.t('page.title')} ${this.getFullName(this.detail)}`
    },

    studentDemoId() {
      return this.$route.params.studentDemoId
    },

    getFullName: () => (detail) => {
      let result = ''

      if (detail?.lastName) {
        result += detail.lastName
      }

      if (detail?.firstName) {
        result += ` ${detail.firstName}`
      }

      if (detail?.middleName) {
        result += ` ${detail.middleName}`
      }

      return result
    },

    /**
     * @returns {boolean}
     */
    canCreateStudentDemo() {
      return this.$can(userActions.CREATE, this.$route) && this.isCreate
    },

    /**
     * @returns {boolean}
     */
    canUpdateStudentDemo() {
      return this.$can(userActions.UPDATE, this.$route) && !this.isCreate
    },

    hasAccessPDStudent() {
      return this.$can(userActions.ACCESS_PD_STUDENT, this.$route)
    },

    hasAccessPDClient() {
      return this.$can(userActions.ACCESS_PD_CLIENT, this.$route)
    },

    firstName: {
      get() {
        return this.detail.firstName || ''
      },

      set(value) {
        this.detail.firstName = value
      },
    },

    lastName: {
      get() {
        return this.detail.lastName || ''
      },

      set(value) {
        this.detail.lastName = value
      },
    },

    middleName: {
      get() {
        return this.detail.middleName || ''
      },

      set(value) {
        this.detail.middleName = value
      },
    },

    password: {
      get() {
        return this.detail.password || ''
      },

      set(value) {
        this.detail.password = value
      },
    },

    demoAccessExpiredAt: {
      get() {
        return this.detail.demoAccessExpiredAt
      },

      set(value) {
        this.detail.demoAccessExpiredAt = value
      },
    },

    grade: {
      get() {
        return this.detail.grade
      },

      set(value) {
        this.detail.grade = value
      },
    },

    email: {
      get() {
        return this.detail.email || ''
      },

      set(value) {
        this.detail.email = value
      },
    },

    phone: {
      get() {
        return this.detail.phone || ''
      },

      set(value) {
        this.detail.phone = value
      },
    },

    parentType() {
      return this.detail.client !== null ? this.t('client') : this.t('lead')
    },

    isEducationTypeSelectDisabled() {
      return !this.detail.school
    },

    isGradeSelectDisabled() {
      return !this.detail.school || !this.detail.educationType
    },
  },

  watch: {
    $route(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.setInitialData()
      }
    },

    'detail.school': function () {
      if (this.isCreate) {
        this.detail.grade = null
        this.detail.educationType = null
      }
    },

    'detail.educationType': function () {
      if (this.isCreate) {
        this.detail.grade = null
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      this.isLoading = true

      try {
        if (!this.isCreate) {
          await this.getDetailsData()
        }

        await this.getSchools()
      } finally {
        this.isLoading = false
      }
    },

    getSchools() {
      return Promise.all([
        ApiOption.getSchools().then(({ data }) => {
          const schoolsDemoAllowed = data.filter(
            (school) => school.isDemoAllowed,
          )

          this.$set(this.options, 'schools', schoolsDemoAllowed)
        }),
      ])
    },

    getGradesAndEducationType() {
      const schoolId = this.detail.school?.id
      const educationTypeId = this.detail?.educationType?.id

      if (educationTypeId && schoolId) {
        ApiOptionsDemoGrades.get({
          school: {
            id: schoolId,
          },
          educationType: {
            id: educationTypeId,
          },
        }).then(({ data }) => {
          this.$set(this.options, 'grades', data)
        })
      }

      if (schoolId) {
        ApiOptionsDemoEducationTypes.get({
          school: {
            id: schoolId,
          },
        }).then(({ data }) => {
          this.$set(this.options, 'educationTypes', data)
        })
      }
    },

    async getDetailsData() {
      await Promise.all([this.getStudentDemoDetail()])
    },

    async save() {
      this.isLoadingBtns.save = true
      try {
        await this.patchStudentDemoDetail()
      } finally {
        this.isLoadingBtns.save = false
      }
    },

    async create() {
      this.isLoadingBtns.create = true
      try {
        await this.postStudentDemoDetail()
      } finally {
        this.isLoadingBtns.create = false
      }
    },

    toTheStudentDemo(studentDemoId) {
      this.$router.push({
        name: 'admin-the-student-demo',
        params: {
          studentDemoId,
        },
      })
    },

    /**
     * @returns {Promise}
     */
    getStudentDemoDetail() {
      return ApiUserStudentDemo.get(this.studentDemoId)
        .then(({ data }) => {
          this.$set(this.$data, 'detail', data)
          this.getGradesAndEducationType()
        })
        .catch((error) => {
          if (error?.response?.status === HttpStatusCodes.NotFound) {
            this.$router.push({
              name: 'admin-students-demo',
            })

            this.$toasted.show(
              `${this.t('toasted_message_demo_student_not_found')}`,
              {
                type: 'error',
              },
            )
          }
        })
    },

    patchStudentDemoDetail() {
      return this.notifyMethods().process(
        ApiUserStudentDemo.patch(this.studentDemoId, this.detail),
      )
    },

    postStudentDemoDetail() {
      const payload = {
        firstName: this.detail.firstName,
        lastName: this.detail.lastName,
        middleName: this.detail.middleName,
        password: this.detail.password,
        email: this.detail.email,
        phone: this.detail.phone,
        grade: this.detail.grade,
        demoAccessExpiredAt: this.detail.demoAccessExpiredAt,
        school: this.detail.school,
        educationType: this.detail.educationType,
      }

      return this.notifyMethods().process(
        ApiUserStudentDemo.post(payload).then(({ data }) => {
          const studentDemoId = data.id

          this.toTheStudentDemo(studentDemoId)
        }),
      )
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`pages.student.the_student_demo.${name}`)
    },
  },
}
</script>
