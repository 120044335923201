import { ApiProductManagementRequestsList } from 'ApiRest/Api/ProductManagement/Requests/List'

export default {
  async fetchRequests({ commit }, filters = {}) {
    const { data, headers } = await ApiProductManagementRequestsList.get(
      filters,
    )

    commit('setFilters', filters)
    commit('setRequests', data)
    commit('setPagination', headers)
  },
}
