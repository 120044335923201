import { ApiOnlineLesson } from 'ApiRest/Api/OnlineLesson'
import { ApiOnlineLessonAnalytics } from 'ApiRest/Api/OnlineLesson/Analytics'
import { ApiOnlineLessonCopy } from 'ApiRest/Api/OnlineLesson/Copy'
import { ApiOnlineLessonCountByRepeatBeginEndDate } from 'ApiRest/Api/OnlineLesson/CountByRepeatBeginEndDate'
import { ApiOnlineLessonGroup } from 'ApiRest/Api/OnlineLesson/Group'
import { ApiOnlineLessonIsRunning } from 'ApiRest/Api/OnlineLesson/IsRunning'
import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import { ApiShowcaseOnlineLesson } from 'ApiRest/Api/OnlineLesson/Showcase/Detail'
import { ApiOnlineLessonShowcaseList } from 'ApiRest/Api/OnlineLesson/Showcase/List'
import ApiUserSettings from 'ApiRest/Api/UserSettings'

export default {
  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOnlineLessons({ commit }, params) {
    const { data } = await ApiOnlineLessonShowcaseList.get(params)

    commit('setOnlineLessons', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionGrades({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getGrades(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionSubjects({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getSubjects(params)

    commit('setOption', {
      key: 'subjects',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionManagers({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getManagers(params)

    commit('setOption', {
      key: 'managers',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionEducationTypes({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getEducationTypes(params)

    commit('setOption', {
      key: 'educationTypes',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   */
  async fetchOptionOnlineLessonRepeatTypes({ commit }) {
    const { data } = await ApiOnlineLessonOption.getOnlineLessonRepeatTypes()

    commit('setOption', {
      key: 'onlineLessonRepeatTypes',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   */
  async createTheOnlineLesson({ commit }, payload) {
    const params = {
      expand: 'conferenceType',
    }

    const { data } = await ApiOnlineLesson.post(payload, params)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   */
  async updateTheOnlineLesson({ commit, getters }, { lessonId, payload }) {
    const params = {
      expand: 'conferenceType',
    }

    const { data } = await ApiOnlineLesson.patch(lessonId, payload, params)

    commit('setTheOnlineLesson', data)

    if (getters.hasName) {
      commit('setTheOnlineLessonBigBlueButtonIsDisabled', false)
    } else {
      commit('setTheOnlineLessonBigBlueButtonIsDisabled', true)
    }
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   * @param {boolean} args.isAll
   * @returns {Array}
   */
  async updateTheOnlineLessonGroup({ commit }, { lessonId, payload, isAll }) {
    payload.editAll = isAll === true

    const { data } = await ApiOnlineLessonGroup.patch(lessonId, payload)

    commit('setTheOnlineLesson', data)

    return data
  },

  /**
   * @param {Object|undefined} context
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   * @returns {Promise}
   */
  copyTheOnlineLesson(context, { lessonId, payload }) {
    return ApiOnlineLessonCopy.put(lessonId, payload)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLesson({ commit, getters }, lessonId) {
    const { data } = await ApiShowcaseOnlineLesson.get(lessonId, {
      expand: 'conferenceType',
    })

    commit('setTheOnlineLesson', data)

    if (getters.hasName) {
      commit('setTheOnlineLessonBigBlueButtonIsDisabled', false)
    }
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLessonIsRunning({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonIsRunning.get(lessonId)

    commit('setTheOnlineLessonIsRunning', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {number} lessonId
   * @returns {Promise}
   */
  deleteTheOnlineLesson(context, lessonId) {
    return ApiOnlineLesson.delete(lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {boolean} args.isAll
   */
  async deleteTheOnlineLessonGroup({ commit }, { lessonId, isAll }) {
    const payload = {
      deleteAll: isAll === true,
    }

    const { data } = await ApiOnlineLessonGroup.delete(lessonId, payload)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLessonAnalytics({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonAnalytics.get(lessonId)

    commit('setTheOnlineLessonAnalytics', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {string|null|undefined} args.newBeginDate
   * @param {string|null|undefined} args.newEndDate
   * @param {number} args.repeatTypeId
   */
  async fetchTheOnlineLessonCountByRepeatEndDate(
    { commit },
    { lessonId, newBeginDate = null, newEndDate = null, repeatTypeId },
  ) {
    const { data } = await ApiOnlineLessonCountByRepeatBeginEndDate.get({
      onlineLessonId: lessonId,
      newBeginDate,
      newEndDate,
      repeatTypeId,
    })

    commit('setTheOnlineLessonCountByRepeatBeginEndDate', data.count)
  },

  /**
   * @param {Object|undefined} context
   * @param {string} date
   * @returns {Promise}
   */
  saveOnlineLessonsPeriodDate(context, date) {
    return ApiUserSettings.put({
      name: 'online-lessons-period-date',
      data: {
        date,
      },
    })
  },

  /**
   * @returns {string|null}
   */
  async fetchOnlineLessonsPeriodDate() {
    const { data } = await ApiUserSettings.get({
      name: 'online-lessons-period-date',
    })

    return data?.data?.date ?? null
  },
}
