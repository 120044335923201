import { ApiCourseManagementParameter } from 'ApiRest/Api/CourseManagement/Products/Parameters/Parameter'
import { ApiCourseManagementParametersList } from 'ApiRest/Api/CourseManagement/Products/Parameters/ParametersList'

export default {
  async fetchParameters({ commit }, filters = {}) {
    const { data, headers } = await ApiCourseManagementParametersList.get(
      filters,
    )

    commit('setParameters', data)
    commit('setPagination', headers)
  },

  removeParameter(context, parameterId) {
    return ApiCourseManagementParameter.delete(parameterId)
  },
}
