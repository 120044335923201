import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Поддержка
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setPermissionByModuleCourseManagement = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  /**
   * @type {{
   *  settings: {
   *    questions: boolean,
   *    ideas: boolean,
   *    faq: boolean
   *  }
   * }|undefined}
   */
  const module = getActiveModule(activeModules, 'educational-courses')

  if (module !== undefined) {
    const productRoutes = routes.get('course-management-product')
    const analyticsRoutes = routes.get('course-management-analytics')
    const orderRoutes = routes.get('course-management-order')
    const bannerRoutes = routes.get('course-management-banner')
    const categoryRoutes = routes.get('course-management-category')
    const parameterRoutes = routes.get('course-management-parameter')
    const requestsRoutes = routes.get('course-management-request')

    if (permission === 'menu_courses_products') {
      helpers.allowReadMenuItemForRoutes(productRoutes)

      return true
    }

    if (permission === 'courses_product_management_index') {
      helpers.allowCrudRoutes(productRoutes)

      return true
    }

    if (permission === 'menu_courses_analytics') {
      helpers.allowReadMenuItemForRoutes(analyticsRoutes)

      return true
    }

    if (permission === 'courses_product_management_analytics') {
      helpers.allowCrudRoutes(analyticsRoutes)

      return true
    }

    if (permission === 'menu_courses_orders') {
      helpers.allowReadMenuItemForRoutes(orderRoutes)

      return true
    }

    if (permission === 'courses_product_management_orders') {
      helpers.allowCrudRoutes(orderRoutes)

      return true
    }

    if (permission === 'menu_courses_banners') {
      helpers.allowReadMenuItemForRoutes(bannerRoutes)

      return true
    }

    if (permission === 'courses_product_management_banners') {
      helpers.allowCrudRoutes(bannerRoutes)

      return true
    }

    if (permission === 'menu_courses_categories') {
      helpers.allowReadMenuItemForRoutes(categoryRoutes)

      return true
    }

    if (permission === 'courses_product_management_categories') {
      helpers.allowCrudRoutes(categoryRoutes)

      return true
    }

    if (permission === 'menu_courses_parameters') {
      helpers.allowReadMenuItemForRoutes(parameterRoutes)

      return true
    }

    if (permission === 'courses_product_management_parameters') {
      helpers.allowCrudRoutes(parameterRoutes)

      return true
    }

    if (permission === 'menu_courses_requests') {
      helpers.allowReadMenuItemForRoutes(requestsRoutes)

      return true
    }

    if (permission === 'courses_product_management_requests') {
      helpers.allowCrudRoutes(requestsRoutes)

      return true
    }
  }

  return false
}
