import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProducPromocodeTypes =
  new (class extends ApiBase {
    /**
     * Промокоды продукта
     *
     * @param {Object} parameters
     * @returns {Promise}
     */
    get(parameters = {}) {
      return this._GET(
        `/product/options/product-promocodes?${this.getUrlParams(parameters)}`,
      )
    }
  })()
