var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("UiKitToggleSwitch", {
        attrs: { checked: _vm.isDark },
        on: { switch: _vm.switchTheme },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }