import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductAgreements =
  new (class extends ApiBase {
    /**
     * Список сохраненных соглашений
     *
     * @returns {Promise}
     */
    get() {
      return this._GET(`/product/options/agreements`)
    }
  })()
