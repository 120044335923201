var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.$style.wiki }, [
    _c(
      "div",
      { class: _vm.$style.reference },
      [
        _c("span", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "mr-3 spinner-border spinner-border-sm text-primary",
          attrs: { role: "status" },
        }),
        _vm._v(" "),
        _vm.canReadWiki
          ? _c("router-link", { attrs: { to: _vm.wikiUrl } }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.header_info_bar.reference")) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ThemeToggle", { staticClass: "ml-3" }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "my-sm-2 my-md-0", class: _vm.$style.name }, [
      _vm.profileFullName
        ? _c("div", { staticClass: "text-right" }, [
            _vm._v("\n      " + _vm._s(_vm.profileFullName) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.profileEmail
        ? _c("div", { staticClass: "text-secondary ml-1" }, [
            _vm._v("\n      (" + _vm._s(_vm.profileEmail) + ")\n    "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }