var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vSelect",
    _vm._g(
      _vm._b(
        {
          ref: "select",
          class: _vm.cssClassSelect,
          attrs: {
            "append-to-body": "",
            "calculate-position": _vm.withPopper,
            label: _vm.label,
            placeholder: _vm.placeholder,
          },
          scopedSlots: _vm._u(
            [
              _vm.showArrow
                ? {
                    key: "open-indicator",
                    fn: function ({ attributes }) {
                      return [
                        _c("span", _vm._b({}, "span", attributes, false), [
                          _c(
                            "svg",
                            {
                              attrs: {
                                height: "8",
                                viewBox: "0 0 12 8",
                                width: "12",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M10.6521 1.80334L5.84875 6.69745L1.00002 1.84872",
                                  stroke: "inherit",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    },
                  }
                : null,
              {
                key: "no-options",
                fn: function ({ loading }) {
                  return [
                    loading
                      ? [_vm._v("Поиск...")]
                      : [
                          _vm._v(
                            "\n      " + _vm._s(_vm.noOptionsTxt) + "\n    "
                          ),
                        ],
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        "vSelect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }