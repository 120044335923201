import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Библиотека
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {Object} data.helpers
 * @param {Function} data.helpers.allowActionOnRoutes
 * @param {Function} data.helpers.disallowActionOnRoutes
 * @returns {boolean}
 */
export const setAbilityByModuleSchoolLibrary = ({
  activeModules,
  helpers: { allowActionOnRoutes, disallowActionOnRoutes },
}) => {
  const module = getActiveModule(activeModules, 'school-library')
  const hasModule = module !== undefined

  const ability = userActions.READ_SCHOOL_LIBRARY

  const routeNames = [
    'admin-students',
    'admin-students-trash',
    'admin-the-student',
  ]

  if (hasModule) {
    allowActionOnRoutes(ability, routeNames)
  } else {
    disallowActionOnRoutes(ability, routeNames)
  }

  return hasModule
}
