export default () => ({
  services: [],

  theService: {
    name: null,
    description: null,
    price: null,
    schools: [],
    school: null,
    schoolEntity: null,
    offer: null,
    receiptTemplate: null,
    canBePaidDolyame: false,
    hideInvoice: false,
  },
})
