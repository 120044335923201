var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("label", { class: _vm.$style.switch }, [
      _c("input", {
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: { checked: _vm.checked },
        on: { change: _vm.onChange },
      }),
      _vm._v(" "),
      _c("span", { class: [_vm.$style.slider, _vm.$style.round] }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }