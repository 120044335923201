import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Поддержка
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setPermissionByModuleSupport = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  /**
   * @type {{
   *  settings: {
   *    questions: boolean,
   *    ideas: boolean,
   *    faq: boolean
   *  }
   * }|undefined}
   */
  const moduleSupport = getActiveModule(activeModules, 'support')

  if (moduleSupport !== undefined) {
    const routeNames = routes.get('admin-support-questions')

    if (
      moduleSupport.settings.questions ||
      moduleSupport.settings.ideas ||
      moduleSupport.settings.faq
    ) {
      helpers.allowReadRoutes(['admin-support'])
    }

    if (permission === 'menu_support') {
      // TODO: 'admin-support' должен быть в routeNames. Проверить.
      helpers.allowReadMenuItemForRoutes(['admin-support', ...routeNames])

      return true
    }

    if (moduleSupport.settings.questions) {
      helpers.allowReadRoutes([
        'admin-support-questions',
        'admin-support-the-question',
        'admin-support-the-question-create',
      ])

      helpers.allowModifyRoutes([
        'admin-support-the-question',
        'admin-support-the-question-create',
      ])

      if (permission === 'support_settings_manage') {
        helpers.allowCrudRoutes(['admin-support-question-settings'])

        return true
      }

      if (
        permission === 'support_question_manage' &&
        moduleSupport?.settings.questions
      ) {
        helpers.allowActionOnRoutes(
          userActions.UPDATE_SUPPORT_QUESTION_MANAGER_FIELDS,
          ['admin-support-the-question'],
        )

        return true
      }
    }

    if (moduleSupport.settings.ideas) {
      helpers.allowReadRoutes(['admin-support-ideas', 'admin-support-the-idea'])

      if (permission === 'support_idea_manage') {
        helpers.allowModifyRoutes(['admin-support-the-idea'])

        return true
      }
    }

    if (moduleSupport.settings.faq) {
      helpers.allowReadRoutes([
        'admin-support-faq',
        'admin-support-the-faq-topic',
        'admin-support-the-faq-question',
      ])

      if (permission === 'support_faq_manage') {
        helpers.allowModifyRoutes([
          'admin-support-the-faq-topic',
          'admin-support-the-faq-question',
          'admin-support-the-faq-topic-create',
          'admin-support-the-faq-question-create',
        ])

        return true
      }
    }
  }

  return false
}
