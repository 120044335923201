export default () => ({
  onlineLessons: [],
  theOnlineLesson: {
    conferenceType: '',
    id: null,
    name: '',
    lessonDate: '',
    timeStart: '',
    timeEnd: '',
    affiliations: [],
    lessonUrl: '',
    lessonUrlHidden: false,
    prereading: '',
    recommendedHomework: '',
    repeatType: null,
    copyTheme: false,
    repeatBeginDate: null,
    repeatEndDate: null,
    additionalInfo: '',
    onlineRoom: null,
    students: [],
    streams: [],
  },
  isBigBlueButtonLessonDisabled: true,
  isOnlineLessonRunning: false,
  newRepeatBeginEndDateCount: null,
  option: {
    schools: [],
    grades: [],
    subjects: [],
    managers: [],
    educationTypes: [],
    onlineLessonRepeatTypes: [],
    onlineLessonRooms: [],
  },
  analytics: [],
})
