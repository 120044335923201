import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementParameter = new (class extends ApiBase {
  /**
   * Получение данных об определённом параметре
   *
   * @param {number} parameterId
   * @returns {Promise}
   */
  get(parameterId) {
    return this._GET(`/courses/products/parameters/${parameterId}`)
  }

  /**
   * Создание продукта
   *
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/courses/products/parameters`, data)
  }

  /**
   * Изменение продукта
   *
   * @param {number} parameterId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(parameterId, data) {
    return this._PATCH(`/courses/products/parameters/${parameterId}`, data)
  }

  /**
   * Удаление продукта
   *
   * @param {number} parameterId
   * @returns {Promise}
   */
  delete(parameterId) {
    return this._DELETE(`/courses/products/parameters/${parameterId}`)
  }
})()
