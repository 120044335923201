import { ApiOrderClientDocument } from 'ApiRest/Api/Order/ClientDocument'
import { ApiOrderStatusOptions } from 'ApiRest/Api/Order/Option/OrderStatusOptions'
import { ApiOrder } from 'ApiRest/Api/Order/Order'

export default {
  async fetchOrder({ commit, state }) {
    const { data } = await ApiOrder.get(state.orderId)

    commit('setOrder', data)
  },

  async updateOrder({ commit, state }) {
    const { data } = await ApiOrder.patch(state.orderId, state.order)

    commit('setOrder', data)
  },

  async fetchOrderStatusOptions({ commit }) {
    const { data } = await ApiOrderStatusOptions.get()

    commit('setOptionsList', {
      path: 'status',
      value: data,
    })
  },

  removeClientDocument(context, { orderId, fileId }) {
    return ApiOrderClientDocument.delete({
      orderId,
      fileId,
    })
  },
}
