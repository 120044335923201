const routes = [
  {
    path: 'product-management/products',
    name: 'admin-product-management-products',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-products" */ '@admin/pages/admin/productManagement/Products.vue'
      ),
  },
  {
    path: 'product-management/products/:productId(\\d+)',
    name: 'admin-the-product-management',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-product-management" */ '@admin/pages/admin/productManagement/TheProductDetail.vue'
      ),
  },
  {
    path: 'product-management/products/create',
    name: 'admin-product-management-create-product',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-create-product" */ '@admin/pages/admin/productManagement/CreateProduct.vue'
      ),
  },
  {
    path: 'product-management/analytics',
    name: 'admin-product-management-analytics',
    meta: {
      accessControlGroup: 'product-management-analytics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-analytics" */ '@admin/pages/admin/productManagement/Analytics.vue'
      ),
  },
  {
    path: 'product-management/orders',
    name: 'admin-product-management-orders',
    meta: {
      accessControlGroup: 'product-management-orders',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-orders" */
        '@admin/pages/admin/productManagement/Orders.vue'
      ),
  },
  {
    path: 'product-management/order/:orderId(\\d+)',
    name: 'admin-product-management-the-order',
    meta: {
      accessControlGroup: 'product-management-orders',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-order" */
        '@admin/pages/admin/productManagement/TheOrder.vue'
      ),
  },
  {
    path: 'product-management/banners',
    name: 'admin-product-management-banners',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-banners" */ '@admin/pages/admin/productManagement/Banners.vue'
      ),
  },
  {
    path: 'product-management/banner/create',
    name: 'admin-product-management-the-banner-create',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-banner" */ '@admin/pages/admin/productManagement/TheBanner.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'product-management/banner/:bannerId(\\d+)',
    name: 'admin-product-management-the-banner',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-banner" */ '@admin/pages/admin/productManagement/TheBanner.vue'
      ),
  },
  {
    path: 'product-management/categories',
    name: 'admin-product-management-categories',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-categories" */ '@admin/pages/admin/productManagement/Categories.vue'
      ),
  },
  {
    path: 'product-management/categories/create',
    name: 'admin-the-product-management-category-create',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-product-management-category-create" */ '@admin/components/admin/productManagement/TheCategoryDetail.vue'
      ),
  },
  {
    path: 'product-management/categories/:categoryId(\\d+)',
    name: 'admin-the-product-management-category',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-product-management-category" */ '@admin/components/admin/productManagement/TheCategoryDetail.vue'
      ),
  },
  {
    path: 'product-management/parameters',
    name: 'admin-product-management-parameters',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-parameters" */ '@admin/pages/admin/productManagement/parameters/Parameters.vue'
      ),
  },
  {
    path: 'product-management/parameter/create',
    name: 'admin-product-management-the-parameter-create',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-parameter" */ '@admin/pages/admin/productManagement/parameters/TheParameter.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'product-management/parameter/:parameterId(\\d+)',
    name: 'admin-product-management-the-parameter',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-parameter" */ '@admin/pages/admin/productManagement/parameters/TheParameter.vue'
      ),
  },
  {
    path: 'product-management/requests',
    name: 'admin-product-management-requests',
    meta: {
      accessControlGroup: 'product-management-requests',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-requests" */ '@admin/pages/admin/productManagement/requests/Requests.vue'
      ),
  },
  {
    path: 'product-management/requests/:requestId(\\d+)',
    name: 'admin-product-management-the-request',
    meta: {
      accessControlGroup: 'product-management-requests',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-management-the-request" */
        '@admin/pages/admin/productManagement/requests/TheRequest.vue'
      ),
  },
]

export default routes
